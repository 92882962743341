import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { FC, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

export interface PinProps extends BoxProps {
  children: ReactNode;
  ref?: Ref<HTMLDivElement>;
}

export const Pin: FC<PinProps> = forwardRef<HTMLDivElement, PinProps>(
  // eslint-disable-next-line react-memo/require-memo
  ({ children, ...props }, ref) => {
    return (
      <Box bottom="0" left="0" pos="absolute" right="0" top="0" {...props} ref={ref}>
        {children}
      </Box>
    );
  }
);
