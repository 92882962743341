import type { AspectRatioProps } from '@chakra-ui/react';
import { AspectRatio, Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { cameraAspectRatioBorders } from '../../../components/Camera/lib/cameraAspectRatioBorders';
import { SquiggleLoader } from '../../../components/FullCenteredSquiggleLoader/lib/SquiggleLoader';
import { Pin } from '../../../components/Pin';

export interface PhotoBoothSpinnerProps {
  ratio: AspectRatioProps['ratio'];
}

export const PhotoBoothSpinner: FC<PhotoBoothSpinnerProps> = memo(({ ratio }) => {
  return (
    <Pin>
      <AspectRatio ratio={ratio} {...cameraAspectRatioBorders} px="200" w="full">
        <Box h="full" w="full">
          <SquiggleLoader />
        </Box>
      </AspectRatio>
    </Pin>
  );
});
