import { Box, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';

import { PhotoBoothContainer } from './PhotoBoothContainer';
import type { PhotoBoothProps } from './types';

export const PhotoBoothCameraError: FC<PhotoBoothProps> = memo(({ onRestart }) => {
  return (
    <PhotoBoothContainer>
      <Box>
        <Text fontWeight="600" textStyle="xl">
          Oh no!
        </Text>
        <Text textStyle="sm">
          We did not successfully gain permissions to use your camera. Please check your browser and
          computer systems to continue.
        </Text>
        <Box>
          <Button as="button" onClick={onRestart} size="sm" type="button">
            Retry
          </Button>
        </Box>
      </Box>
    </PhotoBoothContainer>
  );
});
