import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { AutoCountdownCircle } from '../../../components/CountdownCircle/AutoCountdownCircle';

import { PhotoBoothContainer } from './PhotoBoothContainer';
import { IntroTimeMS } from './photoBoothMachine';
import type { PhotoBoothProps } from './types';

export const PhotoBoothIntro: FC<PhotoBoothProps> = memo(
  ({ numberOfImg, numberOfPrompts, onCancel }) => {
    return (
      <PhotoBoothContainer>
        <>
          <Box textAlign="center" w="full">
            <Box fontWeight="600" textStyle={{ base: 'md', md: 'xl' }}>
              You're about to see {numberOfPrompts} prompts.
            </Box>
            <Box textStyle={{ base: 'xs', md: 'sm' }}>
              The camera will take {numberOfImg} photos.
            </Box>
          </Box>

          <Flex bottom={{ base: '100', md: '500' }} justifyContent="center" pos="absolute" w="full">
            <Button as="button" onClick={onCancel} size="sm" type="button" variant="beige">
              <Flex alignItems="center" gap="200">
                <AutoCountdownCircle dim={20} total={IntroTimeMS / 1000} /> Cancel
              </Flex>
            </Button>
          </Flex>
        </>
      </PhotoBoothContainer>
    );
  }
);
