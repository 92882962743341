import { AspectRatio, Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { cameraAspectRatioBorders } from '../../../components/Camera/lib/cameraAspectRatioBorders';
import { SquiggleLoader } from '../../../components/FullCenteredSquiggleLoader/lib/SquiggleLoader';

import { CompletedFooterDownload } from './CompletedFooterDownload';
import { CompletedFooterNext } from './CompletedFooterNext';
import type { PhotoBoothProps } from './types';

export const PhotoBoothCompleted: FC<PhotoBoothProps> = memo((props) => {
  return (
    <>
      <Flex
        alignItems="center"
        bottom="0"
        flexDir="column"
        justifyContent="center"
        p="100"
        pos="absolute"
        top="0"
        w="full"
      >
        <>
          <AspectRatio ratio={props.ratio} {...cameraAspectRatioBorders} px="200" w="full">
            {props.completedSrc ? (
              <img src={props.completedSrc || ''} />
            ) : (
              <Box h="full" w="full">
                <SquiggleLoader />
              </Box>
            )}
          </AspectRatio>

          {props.onNextProps ? (
            <CompletedFooterNext {...props} />
          ) : (
            <CompletedFooterDownload {...props} />
          )}
        </>
      </Flex>
    </>
  );
});
