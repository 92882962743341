import type { ToXStateSchema } from '@ours/types';
import { append, assertType, noop, noopThrow, wait } from '@ours/utils';
import type { MachineConfig } from 'xstate';
import { assign, createMachine } from 'xstate';

import { toPhotoCollageWithConfetti } from '../../../lib/image/toPhotoCollageWithConfetti';

import type { NumberOfImg } from './types';

export const IntroTimeMS = 4000;
const InitialCountdown = 5;
interface PhotoBoothContext {
  completedSrc: string | null;
  countDownSec: number;
  mountCamera: () => Promise<void>;
  numberOfImages: NumberOfImg;
  onTakePhoto: () => string | Promise<string>;
  photoSrcs: string[];
  stopCamera: () => Promise<void>;
}

const baseContext: PhotoBoothContext = {
  completedSrc: null,
  countDownSec: InitialCountdown,
  mountCamera: noopThrow('initMountCamera'),
  numberOfImages: 2,
  onTakePhoto: noop,
  photoSrcs: [],
  stopCamera: noop,
};

type InitAction = {
  mountCamera: () => Promise<void>;
  numberOfImages: NumberOfImg;
  onTakePhoto: () => string | Promise<string>;
  stopCamera: () => Promise<void>;
  type: 'INIT';
};
type RestartAction = { type: 'RESTART' };
type TickAction = { type: 'TICK' };
type PhotoBoothActions = InitAction | RestartAction | TickAction;

export const PhotoBoothStates = [
  'afterTakePhotos',
  'completed',
  'counting',
  'stichPhotosTogether',
  'init',
  'intro',
  'takePhoto',
  'cameraError',
  'initializeCamera',
] as const;
export type PhotoBoothStatesType = (typeof PhotoBoothStates)[number];

const photoBoothMachineSchema: MachineConfig<
  PhotoBoothContext,
  ToXStateSchema<typeof PhotoBoothStates>,
  PhotoBoothActions
> = {
  context: baseContext,
  description: '',
  id: 'photoBoothMachine',
  initial: 'init',
  predictableActionArguments: true,
  states: {
    afterTakePhotos: {
      always: [{ cond: 'isCompleted', target: 'stichPhotosTogether' }, { target: 'counting' }],
    },
    cameraError: { on: { RESTART: { target: 'init' } } },
    completed: {
      invoke: {
        src: async (ctx) => {
          ctx.stopCamera();
        },
      },
      on: { RESTART: { target: 'init' } },
    },
    counting: {
      after: {
        1000: { actions: 'countdown', cond: 'shouldStillCount', target: 'counting' },
      },
      always: {
        actions: 'resetCountDown',
        cond: 'countDownComplete',
        target: 'takePhoto',
      },
      on: {
        RESTART: { target: 'init' },
      },
    },
    init: {
      invoke: {
        src: async (ctx) => ctx.stopCamera(),
      },
      on: {
        INIT: { actions: 'initPhotoBooth', target: 'initializeCamera' },
      },
    },
    initializeCamera: {
      invoke: {
        onDone: {
          target: 'intro',
        },
        onError: {
          target: 'cameraError',
        },
        src: async (ctx) => {
          await ctx.mountCamera();
        },
      },
    },
    intro: {
      after: { [IntroTimeMS]: { target: 'counting' } },
      on: { RESTART: { target: 'init' } },
    },
    stichPhotosTogether: {
      invoke: {
        onDone: {
          actions: assign({ completedSrc: (_, event) => event.data }),
          target: 'completed',
        },
        src: async (ctx) => {
          return await toPhotoCollageWithConfetti(ctx.photoSrcs);
        },
      },
    },
    takePhoto: {
      invoke: {
        onDone: {
          actions: assign({
            photoSrcs: (ctx, event) => append(event.data, ctx.photoSrcs),
          }),
          target: 'afterTakePhotos',
        },
        src: async (ctx) => {
          await wait(200);
          return await ctx.onTakePhoto();
        },
      },
    },
  },
};

export const photoBoothMachine = createMachine(photoBoothMachineSchema, {
  actions: {
    countdown: assign((ctx) => {
      return { ...ctx, countDownSec: ctx.countDownSec - 1 };
    }),
    initPhotoBooth: assign((_, event) => {
      assertType(event, 'INIT');
      return {
        mountCamera: event.mountCamera,
        numberOfImages: event.numberOfImages,
        onTakePhoto: event.onTakePhoto,
        photoSrcs: [],
        stopCamera: event.stopCamera,
      };
    }),
    resetCountDown: assign((ctx) => ({ ...ctx, countDownSec: InitialCountdown })),
  },
  guards: {
    countDownComplete: (ctx) => ctx.countDownSec === 0,
    isCompleted: (ctx) => ctx.photoSrcs.length === ctx.numberOfImages,
    shouldStillCount: (ctx) => ctx.countDownSec > 0,
  },
});
