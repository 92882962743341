import { unexpected } from '../error/unexpected';

import { isString } from './isString';

type Assert = (str: unknown, errMsg?: string) => asserts str is string;

export const assertIsNonEmptyString: Assert = (str, errMsg) => {
  if (!isString(str) || !str) {
    throw unexpected({ name: errMsg || 'ValueIsNotString' });
  }
};
