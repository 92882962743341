import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';
import { CgRedo } from 'react-icons/cg';

import { Button } from '../../../components/Buttons/Button';
import { IconButton } from '../../../components/Buttons/IconButton';

import type { PhotoBoothProps } from './types';

export const CompletedFooterDownload: FC<PhotoBoothProps> = memo(
  ({ isDownloading, onDownload, onRestart }) => {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={{ base: '50', md: '100' }}
        w="full"
      >
        <Flex gap="100" textAlign="center" w="33%">
          <IconButton
            Icon={CgRedo}
            ariaLabel="Restart photobooth"
            onClick={onRestart}
            type="button"
          />
        </Flex>
        <Box textAlign="center" w="33%">
          <Button
            as="button"
            isLoading={isDownloading}
            onClick={onDownload}
            size="sm"
            type="button"
            variant="beige"
          >
            Download
          </Button>
        </Box>
        <Box textAlign="center" w="33%" />
      </Flex>
    );
  }
);
