import type { FC } from 'react';
import { memo } from 'react';

import { Countdown } from '../../../components/Countdown/Countdown';

import type { PhotoBoothProps } from './types';

export const PhotoBoothCounting: FC<PhotoBoothProps> = memo(({ countDownSec }) => {
  return <Countdown countdownSeconds={countDownSec} />;
});
