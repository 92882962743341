import type { AspectRatioProps } from '@chakra-ui/react';
import { AspectRatio, Box } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { BrandColors } from '@ours/utils';
import {
  Border,
  RoundedCorners,
  assertIsNonEmptyString,
  fileNameWithDate,
  isArray,
  unexpected,
} from '@ours/utils';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useMachine } from '@xstate/react';
import { downloadAUrl } from 'better-file-downloader';
import type { FC } from 'react';
import { memo, useRef } from 'react';

import { CameraCanvas } from '../../components/Camera/lib/CameraCanvas';
import { Flasher } from '../../components/Camera/lib/Flasher';
import { cameraAspectRatioBorders } from '../../components/Camera/lib/cameraAspectRatioBorders';
import { useCamera } from '../../components/Camera/lib/useCamera';
import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useAsyncEvent } from '../../hooks/useAsyncEvent';
import { useColorEnum } from '../../hooks/useColorEnum';
import { useEvent } from '../../hooks/useEvent';
import { safariOverflowRoundedCorner } from '../../lib/safariOverflowRoundedCorner';

import { PhotoBoothCameraError } from './lib/PhotoBoothCameraError';
import { PhotoBoothCompleted } from './lib/PhotoBoothCompleted';
import { PhotoBoothCounting } from './lib/PhotoBoothCounting';
import { PhotoBoothInit } from './lib/PhotoBoothInit';
import { PhotoBoothIntro } from './lib/PhotoBoothIntro';
import { PhotoBoothPrompt } from './lib/PhotoBoothPrompt';
import { PhotoBoothSpinner } from './lib/PhotoBoothSpinner';
import type { PhotoBoothStatesType } from './lib/photoBoothMachine';
import { photoBoothMachine } from './lib/photoBoothMachine';
import type { NumberOfImg, OnNextProps, PhotoBoothProps } from './lib/types';

export interface PhotoBoothIncomingProps {
  color?: BrandColors;
  fileTitle: Maybe<string>;
  introText: Maybe<string>;
  numberOfImg: NumberOfImg;
  onNextProps?: OnNextProps;
  // single prompt can have 2 or 4 photos
  // arr of prompts must match number of photos
  prompts: string | string[];
  ratio?: AspectRatioProps['ratio'];
}

const lookup: Record<PhotoBoothStatesType, FC<PhotoBoothProps>> = {
  afterTakePhotos: () => null,
  cameraError: PhotoBoothCameraError,
  completed: PhotoBoothCompleted,
  counting: PhotoBoothCounting,
  init: PhotoBoothInit,
  initializeCamera: PhotoBoothSpinner,
  intro: PhotoBoothIntro,
  stichPhotosTogether: PhotoBoothCompleted,
  takePhoto: () => null,
};

const createWorker = createWorkerFactory(() => import('../../lib/image/toPhotoFrame'));

export const PhotoBooth: FC<PhotoBoothIncomingProps> = memo((props) => {
  const worker = useWorker(createWorker);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const camera = useCamera({ facingMode: 'user', mount: false });
  const [state, send] = useMachine(photoBoothMachine);

  const color = useColorEnum(props.color, 'yellow');
  const value = state.value as PhotoBoothStatesType;
  const Component = lookup[value];
  const numberOfPrompts = isArray(props.prompts) ? props.prompts.length : 1;
  const currentPrompt = isArray(props.prompts)
    ? props.prompts[state.context.photoSrcs.length] || ''
    : props.prompts;
  const { trackEvent } = useAnalyticsEvent();
  const ratio = props.ratio || 6 / 4;

  const onStart = useEvent(() => {
    trackEvent({ type: 'PHOTOBOOTH_START' });
    send({
      mountCamera: async () => {
        const res = await camera.actions.mountCamera();
        if (!res) {
          throw unexpected({ name: 'MissingCameraPermissions' });
        }
      },
      numberOfImages: props.numberOfImg,
      onTakePhoto: camera.actions.takePhoto,
      stopCamera: camera.actions.stopCamera,
      type: 'INIT',
    });
  });

  const onCancel = useEvent(() => {
    send({ type: 'RESTART' });
  });

  const onRestart = onCancel;

  const [onDownload, isDownloading] = useAsyncEvent(async () => {
    assertIsNonEmptyString(state.context.completedSrc);
    const src = await worker.toPhotoFrame(state.context.completedSrc, 'stacked', '6x4');
    downloadAUrl(src, {
      contentType: 'image/png',
      extension: '.png',
      fileName: fileNameWithDate(props.fileTitle || 'Ours Photo'),
    });
    trackEvent({ type: 'PHOTOBOOTH_DOWNLOAD' });
  });

  const isCameraShowing = ['counting', 'afterTakePhotos', 'takePhoto'].includes(value);

  return (
    <Box
      bg={color}
      border={Border}
      overflow="hidden"
      pos="relative"
      ref={containerRef}
      sx={safariOverflowRoundedCorner}
      w="full"
      {...RoundedCorners.RotatingLg}
      data-testid="Photobooth"
    >
      {isCameraShowing ? <PhotoBoothPrompt color={color} currentPrompt={currentPrompt} /> : null}
      <Component
        completedSrc={state.context.completedSrc}
        countDownSec={state.context.countDownSec}
        introText={
          props.introText ||
          `We're about to give you ${numberOfPrompts} different prompt${
            numberOfPrompts > 1 ? 's' : ''
          } for taking photos about how you express your love.`
        }
        isDownloading={isDownloading}
        numberOfImg={props.numberOfImg}
        numberOfPrompts={numberOfPrompts}
        onCancel={onCancel}
        onDownload={onDownload}
        onNextProps={props.onNextProps}
        onRestart={onRestart}
        onStart={onStart}
        ratio={ratio}
      />
      <Flasher isOpen={value === 'takePhoto'} />
      <AspectRatio
        {...cameraAspectRatioBorders}
        data-testid="CameraSuccess"
        ratio={ratio}
        ref={camera.refs.container}
        visibility={isCameraShowing ? 'visible' : 'hidden'}
      >
        <CameraCanvas
          currentFacingMode={camera.currentFacingMode}
          mode={camera.mode}
          refs={camera.refs}
          state={camera.state}
        />
      </AspectRatio>
    </Box>
  );
});
