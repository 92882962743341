import { Box } from '@chakra-ui/react';
import { Border, RoundedCorners } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  color: string;
  currentPrompt: string;
}

export const PhotoBoothPrompt: FC<Props> = memo(({ color, currentPrompt }) => {
  return (
    <Box
      data-testid="PhotoBoothPrompt"
      display="flex"
      justifyContent="center"
      pos="absolute"
      top={{ base: '-1px', md: '-2px' }}
      w="full"
      zIndex={20}
    >
      <Box
        bg={color}
        border={Border}
        display="inline-block"
        px={{ base: '200', md: '300' }}
        py={{ base: '50', md: '100' }}
        {...RoundedCorners.Bottom}
      >
        {currentPrompt}
      </Box>
    </Box>
  );
});
