import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export interface Props {
  color?: string;
  dim?: number;
  strokeWidth?: number;
  total: number;
}

export const AutoCountdownCircle: FC<Props> = memo(
  ({ color, dim = 20, strokeWidth = 2, total }) => {
    return (
      <CountdownCircleTimer
        colors={(color || Colors.brand.blue) as any}
        duration={total}
        isPlaying
        size={dim}
        strokeWidth={strokeWidth}
      />
    );
  }
);
