import { Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { StaticConfetti } from '../../../components/Confetti/StaticConfetti';

export interface Props {
  children: ReactNode;
}

export const PhotoBoothContainer: FC<Props> = memo(({ children }) => {
  return (
    <>
      <StaticConfetti h="100%" />
      <Flex
        alignItems="center"
        bottom="0"
        flexDir="column"
        justifyContent="center"
        pos="absolute"
        px={{ base: '200', md: '300' }}
        top="0"
        w="full"
      >
        {children}
      </Flex>
    </>
  );
});
