import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface StaticConfettiProps {
  h: string;
}

export const StaticConfetti: FC<StaticConfettiProps> = memo(({ h }) => {
  return (
    <Box
      backgroundImage={`url(/shared/static-confetti.png)`}
      backgroundRepeat="repeat"
      backgroundSize="700px"
      h={h}
      left="0"
      pos="absolute"
      right="0"
      top="0"
      zIndex={0}
    />
  );
});
