import { unexpected } from '@ours/utils';

import { loadImage } from './loadImage';
import type { PhotoRatios } from './photoSizeConstants';
import { PhotoSizeConstants } from './photoSizeConstants';

const ConfettiSrc = '/shared/photobooth/confetti-6x4.png';

const ratioXYLookup: Record<PhotoRatios, [number, number][]> = {
  '6x4': [
    [0, 0],
    [1500, 0],
    [0, 1000],
    [1500, 1000],
  ],
};

export const toPhotoCollageWithConfetti = async (
  src: string[],
  ratio: PhotoRatios = '6x4'
): Promise<string> => {
  if (src.length !== 2 && src.length !== 4) {
    throw unexpected({ name: 'InvalidSrcsLength' });
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { desynchronized: true });
  canvas.height = PhotoSizeConstants[ratio].h;
  canvas.width = PhotoSizeConstants[ratio].w;

  const fourSrcs =
    src.length === 4 ? src : ([src[0], ConfettiSrc, ConfettiSrc, src[1]] as string[]);
  const loadedImages = await Promise.all(fourSrcs.map((src) => loadImage(src)));

  const lookup = ratioXYLookup[ratio];
  loadedImages.forEach((img, idx) => {
    ctx?.drawImage(img, ...lookup[idx]!, 1500, 1000);
  });

  return canvas.toDataURL('image/png');
};
