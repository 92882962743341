import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';
import { CgRedo } from 'react-icons/cg';

import { Button } from '../../../components/Buttons/Button';
import { IconButton } from '../../../components/Buttons/IconButton';
import { useAsyncEvent } from '../../../hooks/useAsyncEvent';
import { objectUrlToBlob } from '../../../lib/video/objectUrlToBlob';

import type { PhotoBoothProps } from './types';

export const CompletedFooterNext: FC<PhotoBoothProps> = memo(
  ({ completedSrc, onDownload, onNextProps, onRestart }) => {
    const [onNext, isLoading] = useAsyncEvent(async () => {
      await onNextProps?.onNextBlob(await objectUrlToBlob(completedSrc || ''));
    });

    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={{ base: '50', md: '100' }}
        w="full"
      >
        <Flex alignItems="center" gap="100" textAlign="center">
          <IconButton
            Icon={CgRedo}
            ariaLabel="Restart photobooth"
            onClick={onRestart}
            type="button"
          />
          <Button as="button" onClick={onDownload} size="sm" type="button" variant="beige">
            Download
          </Button>
        </Flex>

        <Flex alignItems="center" pr="200">
          <Button as="button" isLoading={isLoading} onClick={onNext} size="sm" type="button">
            <Box px="100">{onNextProps?.nextText}</Box>
          </Button>
        </Flex>
      </Flex>
    );
  }
);
