import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';

import { PhotoBoothContainer } from './PhotoBoothContainer';
import type { PhotoBoothProps } from './types';

export const PhotoBoothInit: FC<PhotoBoothProps> = memo(({ introText, onStart }) => {
  return (
    <PhotoBoothContainer>
      <>
        <Box fontWeight="600" textAlign="center" textStyle={{ base: 'md', md: 'xl' }}>
          {introText}
        </Box>
        <Flex bottom={{ base: '100', md: '500' }} justifyContent="center" pos="absolute" w="full">
          <Button as="button" onClick={onStart} type="button">
            Start
          </Button>
        </Flex>
      </>
    </PhotoBoothContainer>
  );
});
